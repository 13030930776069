import { gql } from "@apollo/client";

export const GET_AREAS = gql`
  query ($city_id: Int) {
    areas(city_id: $city_id) {
      id
      name {
        lang
        value
      }
      city {
        name {
          lang
          value
        }
      }
    }
  }
`;

export const GET_AREAS_FILTER = gql`
  query ($city_id: Int, $lang: String) {
    areas(city_id: $city_id) {
      id
      nameString(lang: $lang)
    }
  }
`;

export const GET_AREA = gql`
  query ($id: Int!, $lang: String) {
    areas(id: $id) {
      id
      name {
        lang
        value
      }
      alternative_name {
        lang
        value
      }
      city {
        id
        country {
          id
        }
      }
      lat
      lng
    }
    countries {
      id
      nameString(lang: $lang)
    }
  }
`;
