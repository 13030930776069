import { FormGroup, FormLabel, Modal, Button, Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useMutation } from "@apollo/client";
import { UPDATE_PROP_ESTATE } from "../../../graphql/Mutations/EstateMutation";
import { toast } from "react-toastify";
import Select from "react-select";

export const EditProp = ({ estate_id, prop = null, onCancel, onUpdate }) => {
  const { t } = useTranslation();
  const [showSetting, setShowSetting] = useState(true);
  const [value, setValue] = useState("");
  const [valueRaw, setValueRaw] = useState([]);

  const handleClose = () => {
    onCancel();
  };

  const [modify] = useMutation(UPDATE_PROP_ESTATE);

  const generateField = (type) => {
    switch (type) {
      case 1:
      case 5:
        return (
          <FormGroup>
            <FormLabel>
              {
                prop.property.name.find((p) => p.lang === i18next.language)
                  ?.value
              }
            </FormLabel>
            <input
              type={"text"}
              value={value}
              onInput={(event) => setValue(event.target.value)}
              className={"form-control"}
            />
          </FormGroup>
        );

      case 2:
      case 7:
        return (
          <FormGroup>
            <FormLabel>
              {
                prop.property.name.find((p) => p.lang === i18next.language)
                  ?.value
              }
            </FormLabel>
            <Select
              isMulti={false}
              isClearable={true}
              className="react-select react-select-container mt-1"
              classNamePrefix="react-select"
              options={prop.property?.dictionary.options}
              placeholder={t("Выберите")}
              value={prop.property?.dictionary.options.filter((option) => {
                return valueRaw.includes(option.value);
              })}
              onChange={(e) => {
                setValueRaw([e?.value]);
                setValue(e?.value);
              }}
            ></Select>
          </FormGroup>
        );

      case 4:
      case 8:
        return (
          <FormGroup>
            <FormLabel>
              {
                prop.property.name.find((p) => p.lang === i18next.language)
                  ?.value
              }
            </FormLabel>
            <Select
              isMulti={true}
              isClearable={true}
              className="react-select react-select-container mt-1"
              classNamePrefix="react-select"
              options={prop.property?.dictionary.options}
              placeholder={t("Выберите")}
              value={prop.property?.dictionary.options.filter((option) => {
                return valueRaw.includes(option.value);
              })}
              onChange={(e) => {
                const newValue = [];
                const newValueString = [];
                e.map((item) => {
                  newValue.push(Number(item.value));
                  newValueString.push(item.label);
                });
                setValueRaw(newValue);
                setValue(newValueString.join(", "));
              }}
            ></Select>
          </FormGroup>
        );

      case 6:
        return (
          <FormGroup>
            <FormLabel>
              {
                prop.property.name.find((p) => p.lang === i18next.language)
                  ?.value
              }
            </FormLabel>
            <Row>
              {prop.property?.children.map((child, subIndex) => {
                return (
                  <Col md={6} key={subIndex}>
                    <label>{child.nameString}</label>
                    <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                      <span className="input-group-btn input-group-prepend">
                        <button
                          className="btn btn-primary bootstrap-touchspin-down"
                          type="button"
                          onClick={() => {
                            const _valueRaw = [...valueRaw];
                            _valueRaw[subIndex] =
                              Number(valueRaw[subIndex]) - 1;
                            setValueRaw(_valueRaw);
                          }}
                        >
                          -
                        </button>
                      </span>
                      <input
                        data-toggle="touchspin"
                        type="text"
                        value={valueRaw[subIndex]}
                        className="form-control"
                      />
                      <span className="input-group-btn input-group-append">
                        <button
                          className="btn btn-primary bootstrap-touchspin-up"
                          type="button"
                          onClick={() => {
                            const _valueRaw = [...valueRaw];
                            _valueRaw[subIndex] =
                              Number(valueRaw[subIndex]) + 1;
                            setValueRaw(_valueRaw);
                          }}
                        >
                          +
                        </button>
                      </span>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </FormGroup>
        );
    }
  };

  useEffect(() => {
    if (prop) {
      console.log("🚀Log", prop);
      setShowSetting(true);
      setValue(prop.value);
      const raw = JSON.parse(prop.valueRaw).map((value) => {
        return Number(value);
      });
      setValueRaw(raw);
    } else {
      setShowSetting(false);
    }
  }, [prop]);

  return (
    <Modal show={showSetting}>
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title>{t("Редактирование")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{prop && generateField(prop.property.type)}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={() => {
            const val =
              prop.property.type === 4 || prop.property.type === 6
                ? JSON.stringify(valueRaw)
                : String(value);
            if (Number(val) === 0) {
              toast.error(t("Значение не может быть 0"));
              return false;
            }
            modify({
              variables: {
                estate_id: Number(estate_id),
                property_id: Number(prop.property.id),
                value:
                  prop.property.type === 4 || prop.property.type === 6
                    ? JSON.stringify(valueRaw)
                    : String(value),
                multiple: prop.property.type === 4,
                group:
                  prop.property.type === 6
                    ? JSON.stringify(prop.property.children)
                    : null,
              },
            })
              .then((resp) => {
                if (resp.data.updatePropertyEstate) {
                  onUpdate();
                } else {
                  throw new Error();
                }
              })
              .catch((error) => {
                toast.error(t("Ошибка обновления свойства"));
              });
          }}
        >
          {t("Сохранить")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
