import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import { CountryFormComponent } from "../../components/forms/CountryForm";
import { CityFormComponent } from "../../components/forms/CityFormComponent";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { GET_AREAS_FILTER } from "../../graphql/Queries/AreaQuery";
import { GET_CITIES_FILTER } from "../../graphql/Queries/CityQuery";
import { MODIFY_PLACE } from "../../graphql/Mutations/PlaceMutation";
import { GET_PLACE } from "../../graphql/Queries/PlaceQuery";
import { toast } from "react-toastify";
import { TextInput } from "../../components/Form/TextInput";
import { Loader } from "@googlemaps/js-api-loader";
import axios from "axios";
import { Col, Modal } from "react-bootstrap";

export const FormPlace = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_PLACE);
  const { data } = useQuery(GET_PLACE, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: {
        ru: "",
        az: "",
        en: "",
      },
      alternative_name: {
        ru: "",
        az: "",
        en: "",
      },
      area_id: null,
      city_id: null,
      country_id: null,
      lat: "",
      lng: "",
    },
    validationSchema: Yup.object({
      name: Yup.object().shape({
        ru: Yup.string().required(t("Объязательное поле")),
      }),
      country_id: Yup.number().required(t("Укажите страну")),
      lat: Yup.number().required(t("Объязательное поле")),
      lng: Yup.number().required(t("Объязательное поле")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          name: JSON.stringify(values.name),
          alternative_name: JSON.stringify(values.alternative_name),
          area_id: Number(values.area_id),
          lat: String(values.lat),
          lng: String(values.lng),
        },
      })
        .then((response) => {
          navigate(url);
          Notification(t("Все хорошо!"), "success");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const [getCity] = useLazyQuery(GET_CITIES_FILTER);
  const [getArea] = useLazyQuery(GET_AREAS_FILTER);
  const [arrCity, setArrCity] = useState([]);
  const [arrArea, setArrArea] = useState([]);
  const [showModal, toggleModal] = useState("");

  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    version: "weekly",
    libraries: ["places", "marker"],
  });

  useEffect(() => {
    if (data && data.places[0]) {
      if (data.places[0].area.city.country.id) {
        getCity({
          variables: {
            country_id: Number(data.places[0].area.city.country.id),
            lang: i18next.language,
          },
        })
          .then((resp) => {
            setArrCity(resp.data.cities);
          })
          .catch((error) => {
            toast.error(error.message);
          });
      }
      if (data.places[0].area.city.id) {
        getArea({
          variables: {
            city_id: Number(data.places[0].area.city.id),
            lang: i18next.language,
          },
        })
          .then((resp) => {
            setArrArea(resp.data.areas);
          })
          .catch((error) => {
            toast.error(error.message);
          });
      }
      data.places[0].name.map((dic) => {
        formik.setFieldValue(`name.${dic.lang}`, dic.value);
      });
      data.places[0].alternative_name.map((dic) => {
        formik.setFieldValue(`alternative_name.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("id", data.places[0].id);
      formik.setFieldValue("area_id", data.places[0].area.id);
      formik.setFieldValue("city_id", data.places[0].area.city.id);
      formik.setFieldValue("country_id", data.places[0].area.city.country.id);
      formik.setFieldValue("lat", data.places[0].lat);
      formik.setFieldValue("lng", data.places[0].lng);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Добавить поселок" : "Редактировать поселок"}
        breadcrumbs={[
          {
            slug: url,
            name: "Районы",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Название"}
                    value={formik.values.name}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`name.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.name}
                    name={"name"}
                  />
                </div>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Альтернативное название"}
                    value={formik.values.alternative_name}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`alternative_name.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.alternative_name}
                    name={"alternative_name"}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Страна"}
                    onChange={(value) => {
                      formik.setFieldValue(`country_id`, value);
                      getCity({
                        variables: {
                          country_id: Number(value),
                          lang: i18next.language,
                        },
                      })
                        .then((resp) => {
                          setArrCity(resp.data.cities);
                        })
                        .catch((error) => {
                          toast.error(error.message);
                        });
                    }}
                    error={formik.errors.country_id}
                    name={"country_id"}
                    remotePath={"/countries"}
                    modalForm={
                      <CountryFormComponent
                        data={{ name: { ru: "", en: "", az: "" } }}
                        url={"/countries"}
                        onSuccess={() => {
                          Notification(t("Страна создана"));
                        }}
                      />
                    }
                    rows={{ value: "id", label: "nameString" }}
                    staticOptions={data?.countries}
                    value={formik.values.country_id}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Город"}
                    onChange={(value) => {
                      formik.setFieldValue(`city_id`, value);
                      getArea({
                        variables: {
                          city_id: Number(value),
                          lang: i18next.language,
                        },
                      })
                        .then((resp) => {
                          setArrArea(resp.data.areas);
                        })
                        .catch((error) => {
                          toast.error(error.message);
                        });
                    }}
                    error={formik.errors.city_id}
                    name={"city_id"}
                    remotePath={"/cities"}
                    modalForm={
                      <CityFormComponent
                        data={{
                          name: { ru: "", en: "", az: "" },
                          country_id: null,
                        }}
                        url={"/cities"}
                        onSuccess={() => {
                          Notification(t("Город создан"));
                        }}
                      />
                    }
                    staticOptions={arrCity}
                    rows={{ value: "id", label: "nameString" }}
                    value={formik.values.city_id}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Район"}
                    onChange={(value) => {
                      formik.setFieldValue(`area_id`, value);
                    }}
                    error={formik.errors.area_id}
                    name={"area_id"}
                    staticOptions={arrArea}
                    rows={{ value: "id", label: "nameString" }}
                    value={formik.values.area_id}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    label={"Lat"}
                    value={formik.values.lat}
                    onChange={(event) => {
                      formik.setFieldValue(`lat`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.lat}
                    touched={formik.touched.lat}
                    name={"lat"}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    label={"Lng"}
                    value={formik.values.lng}
                    onChange={(event) => {
                      formik.setFieldValue(`lng`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.lng}
                    touched={formik.touched.lng}
                    name={"lng"}
                  />
                </div>
                <Col md={4}>
                  <button
                    type={"button"}
                    className={"btn btn-success"}
                    style={{ height: 56, width: "49%", marginRight: "2%" }}
                    onClick={() => {
                      const address = formik.values.name.az;
                      if (address === "") {
                        toast.error(t("Сначала укажите адрес"));
                      } else {
                        axios
                          .get(
                            `https://maps.googleapis.com/maps/api/geocode/json?address=Azerbaijan,+${address}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
                          )
                          .then((response) => {
                            formik.setFieldValue(
                              "lat",
                              response.data?.results[0].geometry.location.lat,
                            );
                            formik.setFieldValue(
                              "lng",
                              response.data?.results[0].geometry.location.lng,
                            );
                          })
                          .catch((error) => toast.error(error.message));
                      }
                    }}
                  >
                    {t("Определить координати")}
                  </button>
                  <button
                    type={"button"}
                    className={"btn btn-warning"}
                    style={{ height: 56, width: "49%" }}
                    onClick={() => {
                      toggleModal(true);
                      loader
                        .load()
                        .then((google) => {
                          const map = new google.maps.Map(
                            document.getElementById("map"),
                            {
                              center: {
                                lat: Number(
                                  formik.values.lat === ""
                                    ? 40.401006011050455
                                    : formik.values.lat,
                                ),
                                lng: Number(
                                  formik.values.lng === ""
                                    ? 49.85458884979265
                                    : formik.values.lng,
                                ),
                              },
                              zoom: 16,
                            },
                          );
                          const draggableMarker = new google.maps.Marker({
                            position: {
                              lat: Number(
                                formik.values.lat === ""
                                  ? 40.401006011050455
                                  : formik.values.lat,
                              ),
                              lng: Number(
                                formik.values.lng === ""
                                  ? 49.85458884979265
                                  : formik.values.lng,
                              ),
                            },
                            map,
                            title: "Hello World!",
                            draggable: true,
                          });
                          // const draggableMarker =
                          //   new google.maps.AdvancedMarkerElement({
                          //     map,
                          //     position: {
                          //       lat: 40.401006011050455,
                          //       lng: 49.85458884979265,
                          //     },
                          //     gmpDraggable: true,
                          //     title: "This marker is draggable.",
                          //   });
                          //
                          draggableMarker.addListener("dragend", (event) => {
                            const position = draggableMarker.position;
                            formik.setFieldValue("lat", position.lat());
                            formik.setFieldValue("lng", position.lng());
                          });
                        })
                        .catch((e) => {
                          // do something
                        });
                    }}
                  >
                    {t("Указать на карте")}
                  </button>
                </Col>
                <Modal show={showModal}>
                  <Modal.Header closeButton onClick={() => toggleModal(false)}>
                    <Modal.Title>{t("Координаты")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div id={"map"} style={{ width: "100%", height: 300 }} />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="success"
                      onClick={() => formik.handleSubmit()}
                    >
                      {t("Сохранить")}
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => toggleModal(false)}
                    >
                      {t("Закрыть")}
                    </Button>
                  </Modal.Footer>
                </Modal>
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
