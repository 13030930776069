import React, { useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { Preload } from "../components/Preload";
import { GET_OBJECT_TYPE } from "../graphql/Queries/ObjectTypeQuery";
import { DELETE_OBJECT_TYPE } from "../graphql/Mutations/ObjectTypeMutation";
import { finder, TARGET } from "../utils/Dictionaries";

export const TypeObjects = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();
  const { loading, error, data } = useQuery(GET_OBJECT_TYPE, {
    fetchPolicy: "no-cache",
  });
  const [drop] = useMutation(DELETE_OBJECT_TYPE);

  const remove = (id) => {
    drop({
      variables: {
        id: Number(id),
      },
    })
      .then((response) => {
        setItems(response.data.deleteObjectType);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  const goDesign = (id) => {
    navigator(`${url}/design?id=${id}`);
  };

  const goTitleFormat = (id) => {
    navigator(`${url}/title?id=${id}`);
  };

  const goThumbProp = (id) => {
    navigator(`${url}/thumb-props?id=${id}`);
  };

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle title={"Типы объектов"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                data={data.objectType}
                columns={[
                  { key: "id", label: "ID" },
                  { key: "name", label: "Название" },
                  {
                    key: "target",
                    label: "Тип сделки",
                    callback: (item, row) => {
                      return finder(TARGET, item);
                    },
                    list: TARGET,
                    listProp: {
                      value: "id",
                      label: "name",
                      search: "target",
                    },
                  },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: `${url}/form`,
                    icon: "fe-plus",
                    name: "Создать",
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
                buttons={[
                  {
                    onClick: (row) => {
                      navigator(`${url}/quick-filter?id=${row.id}`);
                    },
                    icon: "mdi mdi-air-filter",
                    title: "Быстрые фильтры",
                  },
                  {
                    onClick: (row) => {
                      navigator(`${url}/item-sort?id=${row.id}`);
                    },
                    icon: "mdi mdi-filter-variant",
                    title: "Порядок вывода свойств на странице объекта",
                  },
                  {
                    onClick: (row) => {
                      navigator(`${url}/filter-sort?id=${row.id}`);
                    },
                    icon: "mdi mdi-filter-menu-outline",
                    title: "Порядок вывода свойств при фильтрации",
                  },
                  {
                    onClick: (row) => {
                      navigator(`${url}/props-around?id=${row.id}`);
                    },
                    icon: "mdi mdi-account-multiple",
                    title: "Настройки похожих",
                  },
                  {
                    onClick: (row) => {
                      goThumbProp(row.id);
                    },
                    icon: "mdi mdi-dots-grid",
                    title: "Свойста для блока",
                  },
                  {
                    onClick: (row) => {
                      goTitleFormat(row.id);
                    },
                    icon: "mdi mdi-select-compare",
                    title: "Формирование заголовка",
                  },
                  {
                    onClick: (row) => {
                      goDesign(row.id);
                    },
                    title: "Порядок вывода шагов",
                    icon: "mdi mdi-sort",
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
