import { gql } from "@apollo/client";

export const MODIFY_AREA = gql`
  mutation (
    $id: Int!
    $name: String!
    $alternative_name: String!
    $city_id: Int!
    $lat: String!
    $lng: String!
  ) {
    modifyArea(
      id: $id
      name: $name
      city_id: $city_id
      lat: $lat
      lng: $lng
      alternative_name: $alternative_name
    )
  }
`;

export const DELETE_AREA = gql`
  mutation ($id: Int!) {
    deleteArea(id: $id) {
      id
      name {
        lang
        value
      }
      city {
        name {
          lang
          value
        }
      }
    }
  }
`;
