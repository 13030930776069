import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Modal,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { isEmpty, isObject, orderBy } from "lodash";
import Switch from "react-switch";
import { Link, useNavigate } from "react-router-dom";
import i18next from "i18next";
import Select from "react-select";
import { Paginator } from "./Paginator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export const CustomTable = ({
  columns, // стовпці
  data, // дані
  search, // доступний пошук?
  sort = false, // доступне сортування?
  limit = 10, // к-сть на сторінці
  actions = [], // дії
  onClick, // подія при кліку на рядок
  onDelete, // при видалені
  primaryKey = "id", // ключ по замовчуванню
  canDelete = null, // дозволено видалення?
  buttons = [], // додаткові кнопки для елементу
  totalPage = false, // к-сть сторінок (якщо серверний рендеринг)
  onChangePage = null, // подія при зміні сторінки (якщо серверний рендеринг)
  sortIndex = 0, // поле, по якому йде сортування по замовчуванню
  serverRender = false, // серверний рендеринг?
  onSearch, // подія при пошуку
  onSort, // подія при сортуванні
  initSearchObject = {}, // параметри пошуку при ініціалізації
}) => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [sorted, setSorted] = useState(null);
  const [arrowSort, setArrowSort] = useState("down");
  const [showSetting, setShowSetting] = useState(false);
  const [fields, setFields] = useState(columns);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchArr, setSearchArr] = useState(initSearchObject);
  const navigate = useNavigate();
  const [allPage, setAllPage] = useState(0);

  useEffect(() => {
    const _data = data.map((d) => {
      const _d = { ...d };
      _d.id = Number(_d.id);
      return _d;
    });
    if (sort && !serverRender) {
      const sortedItems = orderBy(_data, [columns[sortIndex].key], ["desc"]);
      setItems(sortedItems);
    } else {
      setItems(_data);
    }
  }, [data]);

  useEffect(() => {
    if (sort && sorted !== null) {
      if (serverRender) {
        onSort({ row: sorted, arrow: arrowSort === "down" ? "desc" : "asc" });
      } else {
        const _items = [...items];
        const sortedItems = orderBy(
          _items,
          [
            (item) => {
              const parts = sorted.split(".");
              if (parts.length > 1) {
                let res = item;
                parts.map((part) => {
                  res = res[part];
                });
                const nameObj = res.find((n) => n.lang === i18next.language);
                return nameObj ? nameObj.value : "";
              }
              if (!isObject(item[sorted])) {
                return item[sorted];
              }
              const nameObj = item[sorted].find(
                (n) => n.lang === i18next.language,
              );
              return nameObj ? nameObj.value : "";
            },
          ],
          [arrowSort === "down" ? "desc" : "asc"],
        );
        setItems(sortedItems);
      }
    }
  }, [sort, sorted, arrowSort]);

  useEffect(() => {
    const _fields = columns.map((column) => {
      column.show = true;
      return column;
    });
    setFields(_fields);
  }, [columns]);

  // поиск
  useEffect(() => {
    if (serverRender) {
      if (!isEmpty(searchArr)) {
        onSearch(searchArr);
      }
    } else {
      let _items = [...data];
      Object.keys(searchArr).map((key) => {
        if (searchArr[key] !== "") {
          _items = _items.filter((item) => {
            const valueRow = formatValue(item, key);
            console.log("[Log]", valueRow);
            if (valueRow === null || valueRow === undefined) {
              return false;
            }
            return (
              JSON.stringify(valueRow)
                .toLowerCase()
                .indexOf(String(searchArr[key]).toLowerCase()) !== -1
            );
          });
        }
      });
      setItems(_items);
    }
  }, [searchArr]);

  const handleClose = () => {
    setShowSetting(false);
  };

  const formatValue = (row, key) => {
    if (isObject(row[key])) {
      if (row[key].length === undefined) {
        return JSON.stringify(row[key]);
      }
      const d = row[key].find((r) => {
        return r.lang === i18next.language;
      });
      return d?.value;
    }
    const parts = key.split(".");
    if (parts.length === 1) {
      return row[key];
    } else {
      let current = row;
      for (let part of parts) {
        if (current !== null && current[part] !== undefined) {
          current = current[part];
        } else if (current !== null) {
          const t = current.find((cur) => {
            return cur.lang === part;
          });
          current = t.value;
        }
      }
      return current;
    }
  };

  useEffect(() => {
    setAllPage(totalPage ? totalPage : items.length);
  }, [items]);

  const recurseArray = (arr, key) => {};
  const amountPages = Math.ceil(items.length / limit);

  const partData = (items) => {
    return totalPage
      ? items
      : items.slice((currentPage - 1) * 10, currentPage * 10);
  };

  const searchInput = (column) => {
    if (column?.list) {
      let defValue = null;
      if (searchArr[column.key] !== undefined) {
        defValue = column.list.find((item) => {
          return (
            Number(item[column.listProp.value]) ===
            Number(searchArr[column.key])
          );
        });
      }
      return (
        <Select
          options={column.list}
          placeholder={t("Поиск")}
          getOptionLabel={(element) => element[column.listProp.label]}
          getOptionValue={(element) => element[column.listProp.value]}
          onChange={(value) => {
            const _searchArr = { ...searchArr };
            _searchArr[
              column.listProp?.search ? column.listProp?.search : column.key
            ] = value ? value[column.listProp.value] : "";
            setSearchArr(_searchArr);
          }}
          defaultValue={defValue}
          isClearable={true}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              width: 150,
              fontWeight: "normal",
            }),
          }}
        />
      );
    }
    if (column?.isDate) {
      return (
        <DatePicker
          isClearable={true}
          dateFormat={"dd.MM.YYYY"}
          selected={searchArr[column.key] !== "" ? searchArr[column.key] : null}
          onChange={(date) => {
            const _searchArr = { ...searchArr };
            _searchArr[column.key] =
              date === null ? "" : moment(date).format("YYYY-MM-DD");
            setSearchArr(_searchArr);
          }}
          locale={i18next.language}
          className={"form-control form-control-md input-filter"}
          placeholderText={t("Выберите дату")}
        />
      );
    }
    return (
      <input
        className={"form-control form-control-md input-filter"}
        data-filter={column.key}
        placeholder={t("Поиск")}
        onInput={(event) => {
          const _searchArr = { ...searchArr };
          _searchArr[column.key] = event.target.value;
          setSearchArr(_searchArr);
        }}
      />
    );
  };

  return (
    <div className={"custom-table"}>
      <Modal show={showSetting}>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>{t("Настройка полей")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fields.map((field, index) => {
            return (
              <div className={"by-switch"} key={index}>
                <Switch
                  onChange={() => {
                    const _fields = [...fields];
                    const _newFields = _fields.map((column) => {
                      if (column.key === field.key) {
                        column.show = !column.show;
                      }
                      return column;
                    });
                    setFields(_newFields);
                  }}
                  checked={field.show === undefined ? true : field.show}
                  onColor={"#3bafda"}
                />
                <span>{field.label}</span>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Закрыть")}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={"header"}>
        <Dropdown className={"action"}>
          <Dropdown.Toggle>{t("Действия")}</Dropdown.Toggle>
          <Dropdown.Menu>
            {actions.map((action, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    action.callback ? action.callback() : navigate(action.slug);
                  }}
                >
                  <i className={action.icon}></i> {t(action.name)}
                </Dropdown.Item>
              );
            })}
            <Dropdown.Item onClick={() => setShowSetting(true)}>
              <i className={"fe-settings"}></i> {t("Настроить")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={"body-by-table min-h-500"}>
        <Table hover={true}>
          <thead>
            <tr>
              {fields.map((column) => {
                if (column.show) {
                  const columnSort = column.sort ? column.sort : column.key;
                  return (
                    <th key={column.key}>
                      <div
                        style={{ userSelect: "none" }}
                        onClick={() => {
                          if (sorted === columnSort) {
                            setArrowSort(arrowSort === "down" ? "up" : "down");
                          } else {
                            setSorted(columnSort);
                            setArrowSort("down");
                          }
                        }}
                      >
                        {column.label}
                        {sorted === columnSort && (
                          <i className={`mdi mdi-arrow-${arrowSort}`}></i>
                        )}
                      </div>
                      {search && searchInput(column)}
                    </th>
                  );
                }
              })}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {partData(items).map((row, index) => {
              return (
                <tr key={index} onClick={() => onClick(row[primaryKey])}>
                  {fields.map((field) => {
                    if (field.show) {
                      return (
                        <td key={field.key}>
                          {field.callback
                            ? field.callback(row[field.key], row)
                            : formatValue(row, field.key)}
                        </td>
                      );
                    }
                  })}
                  <td style={{ width: buttons.length * 50 + 50 }}>
                    {(canDelete === null || canDelete(row)) && (
                      <ul className="list-inline table-action m-0">
                        {buttons.map((button) => {
                          return (
                            <li className="list-inline-item">
                              <OverlayTrigger
                                placement={"left"}
                                overlay={
                                  button?.title ? (
                                    <Tooltip>{button.title}</Tooltip>
                                  ) : (
                                    ""
                                  )
                                }
                              >
                                <div
                                  className="action-icon"
                                  onClick={(event) => {
                                    button.onClick(row);
                                    event.stopPropagation();
                                  }}
                                >
                                  {" "}
                                  <i className={button.icon}></i>
                                </div>
                              </OverlayTrigger>
                            </li>
                          );
                        })}
                        <li className="list-inline-item">
                          <Link
                            className="action-icon"
                            onClick={(event) => {
                              event.stopPropagation();
                              if (
                                window.confirm(
                                  t("Вы действительно хотите удалить?"),
                                )
                              ) {
                                if (row.id !== undefined) {
                                  onDelete(row.id);
                                } else {
                                  onDelete(row);
                                }
                              }
                            }}
                          >
                            {" "}
                            <i className="mdi mdi-delete"></i>
                          </Link>
                        </li>
                      </ul>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className={"custom-footer"}>
        <div className={"total"}>
          <span>
            {t("Показано от {{start}} до {{end}} из {{total}}", {
              start: (currentPage - 1) * 10 + 1,
              end: currentPage * 10 < allPage ? currentPage * 10 : allPage,
              total: allPage,
            })}
          </span>
        </div>
        {allPage > limit && (
          <Paginator
            amountPages={
              !totalPage ? amountPages : Math.ceil(totalPage / limit)
            }
            currentPage={currentPage}
            setCurrentPage={(id) => {
              setCurrentPage(id);
              if (onChangePage) {
                onChangePage(id);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
