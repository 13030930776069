import React, { useEffect, useRef, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_ANALYTIC_ESTATE,
  GET_ESTATES,
} from "../graphql/Queries/EstateQuery";
import moment from "moment";
import { DELETE_ESTATE } from "../graphql/Mutations/EstateMutation";
import i18next from "i18next";
import { setBadge } from "../redux/slices/badgeSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateQuery } from "../redux/slices/tableSlice";
import { Modal } from "react-bootstrap";
import { Button } from "../components/Button";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const Estates = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const searchTimer = useRef(null);
  const query = useSelector((state) => state.table.estates);
  const [sort, setSort] = useState(false);
  const [totalEstates, setTotalEstates] = useState(0);
  const dispatch = useDispatch();
  const [showStat, setShowStat] = useState(false);
  const { t } = useTranslation();
  const [analyticData, setAnalyticData] = useState({ show: 0, view: 0 });

  const { data } = useQuery(GET_ESTATES, {
    fetchPolicy: "no-cache",
    variables: {
      page: currentPage,
      query: JSON.stringify(query),
      sort: !sort ? "" : JSON.stringify(sort),
      lang: i18next.language,
    },
  });
  const [drop] = useMutation(DELETE_ESTATE);
  const [getAnalytic] = useLazyQuery(GET_ANALYTIC_ESTATE);

  useEffect(() => {
    if (data) {
      setItems(data.estate.data);
      setTotalEstates(data?.estate?.total);
      const badge = JSON.parse(data.badge);
      dispatch(setBadge(badge));
    }
  }, [data]);

  const remove = (id) => {
    drop({
      variables: {
        id: Number(id),
        page: currentPage,
        query: JSON.stringify(query),
        sort: !sort ? "" : JSON.stringify(sort),
        lang: i18next.language,
      },
    })
      .then((response) => {
        setItems(response.data.deleteEstate.data);
        setTotalEstates(response.data.deleteEstate.total);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  return (
    <Wrapper>
      <PageTitle title={"Объвления"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                totalPage={totalEstates}
                data={items}
                initSearchObject={query}
                columns={[
                  {
                    key: "id",
                    label: "ID",
                    callback: (item, row) => {
                      return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {(Number(row.status_id) === 2 ||
                            Number(row.status_id) === 6) && (
                            <i
                              className={"ri-alert-line"}
                              style={{ color: "red", marginRight: "5px" }}
                            />
                          )}
                          {item}
                        </div>
                      );
                    },
                  },
                  {
                    key: "published_at",
                    label: "Дата публикации",
                    callback: (item) => {
                      return item !== null
                        ? moment(item).format("HH:mm DD.MM.YYYY")
                        : "";
                    },
                    isDate: true,
                  },
                  {
                    key: "updated_at",
                    label: "Дата обновления",
                    callback: (item) => {
                      return item !== null
                        ? moment(item).format("HH:mm DD.MM.YYYY")
                        : "";
                    },
                    isDate: true,
                  },
                  {
                    key: "object_type_id",
                    label: "Тип недвижимости",
                    list: data?.objectType,
                    listProp: {
                      value: "id",
                      label: "nameWithTarget",
                      search: "object_type_id",
                    },
                  },
                  { key: "title", label: "Название" },
                  {
                    key: "user",
                    label: "Номер телефона",
                    callback: (item) => {
                      return item.phone;
                    },
                  },
                  {
                    key: "status",
                    label: "Состояние",
                    list: data?.listStatus,
                    listProp: {
                      value: "id",
                      label: "name",
                      search: "status",
                    },
                  },
                ]}
                search={true}
                sort={true}
                sortIndex={4}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: `${url}/form`,
                    icon: "fe-plus",
                    name: "Создать",
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
                onChangePage={(page) => setCurrentPage(page)}
                serverRender={true}
                onSearch={(data) => {
                  clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    dispatch(updateQuery({ page: "estates", data: data }));
                  }, 1000);
                }}
                onSort={(data) => {
                  setSort(data);
                }}
                buttons={[
                  {
                    onClick: (row) => {
                      getAnalytic({
                        variables: {
                          id: Number(row.id),
                        },
                      })
                        .then((response) => {
                          setAnalyticData(
                            JSON.parse(response.data.estateAnalytic),
                          );
                          setShowStat(true);
                        })
                        .catch((error) => {
                          toast.error(t("Ошибка получения статистики"));
                        });
                    },
                    icon: "mdi mdi-chart-bar",
                    title: "Статистика",
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal show={showStat}>
        <Modal.Header closeButton onClick={() => setShowStat(false)}>
          <Modal.Title>{t("Статистика за все время")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label className={"form-label"}>{t("Показов в поиске")}: </label>
            <span style={{ marginLeft: 6 }}>{analyticData.show}</span>
          </div>
          <div>
            <label className={"form-label"}>
              {t("Просмотр за весь период")}:
            </label>
            <span style={{ marginLeft: 6 }}>{analyticData.view}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowStat(false)}>
            {t("Закрыть")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
};
