import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import { CountryFormComponent } from "../../components/forms/CountryForm";
import { CityFormComponent } from "../../components/forms/CityFormComponent";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { GET_AREAS_FILTER } from "../../graphql/Queries/AreaQuery";
import { GET_CITIES_FILTER } from "../../graphql/Queries/CityQuery";
import { toast } from "react-toastify";
import {
  GET_COMPLEX,
  GET_COMPLEX_PROPS,
} from "../../graphql/Queries/ComplexQuery";
import { Col, Row } from "react-bootstrap";
import { TextInput } from "../../components/Form/TextInput";
import { GET_PLACES_FILTER } from "../../graphql/Queries/PlaceQuery";
import { FileInput } from "../../components/Form/FileInput";
import { MODIFY_COMPLEX } from "../../graphql/Mutations/ComplexMutation";
import { Editor } from "../../components/Form/Editor";

export const FormComplex = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_COMPLEX);
  const { data } = useQuery(GET_COMPLEX, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  const [complexProps, setComplexProps] = useState([]);
  const [complexPropQuery] = useLazyQuery(GET_COMPLEX_PROPS);
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: {
        ru: "",
        az: "",
        en: "",
      },
      text: {
        ru: "",
        az: "",
        en: "",
      },
      place_id: null,
      area_id: null,
      city_id: null,
      country_id: null,
      address: "",
      image: [],
      lat: "",
      lng: "",
      properties: {},
      type: 1,
      slug: "",
    },
    validationSchema: Yup.object({
      name: Yup.object().shape({
        ru: Yup.string().required(t("Объязательное поле")),
      }),
      city_id: Yup.number().required(t("Укажите страну")),
      slug: Yup.string().required(t("Обязательное поле")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          name: JSON.stringify(values.name),
          text: JSON.stringify(values.text),
          city_id: Number(values.city_id),
          area_id: Number(values.area_id),
          place_id: Number(values.place_id),
          type: Number(values.type),
          address: values.address,
          lat: values.lat,
          lng: values.lng,
          image: JSON.stringify(values.image),
          properties: JSON.stringify(values.properties),
          slug: values.slug,
        },
      })
        .then((response) => {
          if (!response.data.modifyComplex) {
            toast.error(t("Ошибка сохранения"));
          } else {
            navigate(url);
            Notification(t("Все хорошо!"), "success");
          }
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const [getCity] = useLazyQuery(GET_CITIES_FILTER);
  const [getArea] = useLazyQuery(GET_AREAS_FILTER);
  const [getPlace] = useLazyQuery(GET_PLACES_FILTER);
  const [arrCity, setArrCity] = useState([]);
  const [arrArea, setArrArea] = useState([]);
  const [arrPlace, setArrPlace] = useState([]);
  const loadCity = (value) => {
    getCity({
      variables: {
        country_id: value,
        lang: i18next.language,
      },
    })
      .then((resp) => {
        setArrCity(resp.data.cities);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const loadArea = (value) => {
    getArea({
      variables: {
        city_id: value,
        lang: i18next.language,
      },
    })
      .then((resp) => {
        setArrArea(resp.data.areas);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  const loadPlace = (value) => {
    getPlace({
      variables: {
        area_id: value,
        lang: i18next.language,
      },
    })
      .then((resp) => {
        setArrPlace(resp.data.places);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (data && data.complex[0]) {
      data.complex[0].name.map((dic) => {
        formik.setFieldValue(`name.${dic.lang}`, dic.value);
      });
      data.complex[0].text.map((dic) => {
        formik.setFieldValue(`text.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("id", data.complex[0].id);
      formik.setFieldValue("city_id", data.complex[0].city?.id);
      formik.setFieldValue("country_id", data.complex[0].city?.country?.id);
      formik.setFieldValue("area_id", data.complex[0].area?.id);
      formik.setFieldValue("place_id", data.complex[0].place?.id);
      formik.setFieldValue("address", data.complex[0].address);
      formik.setFieldValue("lat", data.complex[0].lat);
      formik.setFieldValue("lng", data.complex[0].lng);
      formik.setFieldValue("type", String(data.complex[0].type));
      formik.setFieldValue("slug", data.complex[0].slug);
      const currentImage = JSON.parse(data.complex[0].image);
      if (currentImage) {
        formik.setFieldValue("image", currentImage);
      }
      formik.setFieldValue(
        "properties",
        JSON.parse(data.complex[0].properties),
      );
      loadCity(Number(data.complex[0].city.country.id));
      loadArea(Number(data.complex[0].city.id));
      if (data.complex[0].area) {
        loadPlace(Number(data.complex[0].area.id));
      }
      if (data.complex[0].type !== null) {
        complexPropQuery({
          variables: {
            lang: i18next.language,
            type: Number(data.complex[0].type),
          },
        }).then((resp) => {
          setComplexProps(resp.data.complexProperty);
        });
      }
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Добавить ЖК" : "Редактировать ЖК"}
        breadcrumbs={[
          {
            slug: url,
            name: "ЖК",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Название"}
                    value={formik.values.name}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`name.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.name}
                    name={"name"}
                  />
                </div>
                <div className="col-md-6 pt-4">
                  <TextInput
                    label={"Ссылка"}
                    value={formik.values.slug}
                    onChange={(event) => {
                      formik.setFieldValue(`slug`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.slug}
                    name={"slug"}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Тип"}
                    onChange={(value) => {
                      formik.setFieldValue(`type`, value);
                      complexPropQuery({
                        variables: {
                          lang: i18next.language,
                          type: Number(value),
                        },
                      }).then((resp) => {
                        setComplexProps(resp.data.complexProperty);
                      });
                    }}
                    error={formik.errors.type}
                    name={"type"}
                    rows={{ value: "id", label: "name" }}
                    staticOptions={data?.list}
                    value={formik.values.type}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Страна"}
                    onChange={(value) => {
                      formik.setFieldValue(`country_id`, value);
                      loadCity(Number(value));
                    }}
                    error={formik.errors.country_id}
                    name={"country_id"}
                    remotePath={"/countries"}
                    modalForm={
                      <CountryFormComponent
                        data={{ name: { ru: "", en: "", az: "" } }}
                        url={"/countries"}
                        onSuccess={() => {
                          Notification(t("Страна создана"));
                        }}
                      />
                    }
                    rows={{ value: "id", label: "nameString" }}
                    staticOptions={data?.countries}
                    value={formik.values.country_id}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Город"}
                    onChange={(value) => {
                      formik.setFieldValue(`city_id`, value);
                      loadArea(Number(value));
                    }}
                    error={formik.errors.city_id}
                    name={"city_id"}
                    remotePath={"/cities"}
                    modalForm={
                      <CityFormComponent
                        data={{
                          name: { ru: "", en: "", az: "" },
                          country_id: null,
                        }}
                        url={"/cities"}
                        onSuccess={() => {
                          Notification(t("Город создан"));
                        }}
                      />
                    }
                    staticOptions={arrCity}
                    rows={{ value: "id", label: "nameString" }}
                    value={formik.values.city_id}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Район"}
                    onChange={(value) => {
                      formik.setFieldValue(`area_id`, value);
                      loadPlace(Number(value));
                    }}
                    error={formik.errors.area_id}
                    name={"area_id"}
                    staticOptions={arrArea}
                    rows={{ value: "id", label: "nameString" }}
                    value={formik.values.area_id}
                    style={{ paddingRight: 0 }}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Поселок"}
                    onChange={(value) => {
                      formik.setFieldValue(`place_id`, value);
                    }}
                    error={formik.errors.place_id}
                    name={"place_id"}
                    staticOptions={arrPlace}
                    rows={{ value: "id", label: "nameString" }}
                    value={formik.values.place_id}
                    style={{ paddingRight: 0 }}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Адрес"}
                    onChange={(event) => {
                      formik.setFieldValue("address", event.target.value);
                    }}
                    value={formik.values.address}
                    name={"address"}
                    error={formik.errors.address}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Координата lat"}
                    onChange={(event) => {
                      formik.setFieldValue("lat", event.target.value);
                    }}
                    value={formik.values.lat}
                    name={"lat"}
                    error={formik.errors.lat}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Координата lng"}
                    onChange={(event) => {
                      formik.setFieldValue("lng", event.target.value);
                    }}
                    value={formik.values.lng}
                    name={"lng"}
                    error={formik.errors.lng}
                  />
                </div>
                <div className="col-md-12">
                  <Editor
                    value={formik.values.text}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`text.${lang}`, text);
                    }}
                    name={"text"}
                    label={"Описание"}
                  />
                </div>
                <div className="col-md-12">
                  <FileInput
                    onSort={(images) => {
                      formik.setFieldValue(`image`, images);
                    }}
                    label={"Фото"}
                    onChange={(file) => {
                      const _image = [...formik.values.image];
                      _image.push(file);
                      formik.setFieldValue(`image`, _image);
                    }}
                    onDelete={(file) => {
                      const _image = [...formik.values.image];
                      _image.splice(_image.indexOf(file), 1);
                      formik.setFieldValue(`image`, _image);
                    }}
                    value={formik.values.image}
                    multilang={false}
                  />
                </div>
                {complexProps.map((prop, index) => {
                  switch (prop.property.type) {
                    case 1:
                    case 5:
                      return (
                        <Col md={6} key={index}>
                          <TextInput
                            label={prop.property.nameString}
                            onChange={(event) => {
                              const _properties = {
                                ...formik.values.properties,
                              };
                              _properties[prop.property.id] =
                                event.target.value;
                              formik.setFieldValue("properties", _properties);
                            }}
                            value={formik.values.properties[prop.property.id]}
                          />
                        </Col>
                      );

                    case 7:
                    case 2:
                      return (
                        <Col md={6} key={index}>
                          <Selected
                            label={prop.property.nameString}
                            onChange={(event) => {
                              const _properties = {
                                ...formik.values.properties,
                              };
                              _properties[prop.property.id] = event;
                              formik.setFieldValue("properties", _properties);
                            }}
                            staticOptions={prop.property.dictionary.options}
                            rows={{ value: "id", label: "valueString" }}
                            style={{ paddingRight: 0 }}
                            value={Number(
                              formik.values.properties[prop.property.id],
                            )}
                          />
                        </Col>
                      );

                    case 4:
                      return (
                        <Col md={6} key={index}>
                          <Selected
                            label={prop.property.nameString}
                            onChange={(event) => {
                              const _properties = {
                                ...formik.values.properties,
                              };
                              const _arr = [];
                              event.map((ev) => {
                                _arr.push(ev.value);
                              });
                              _properties[prop.property.id] = _arr;
                              formik.setFieldValue("properties", _properties);
                            }}
                            staticOptions={prop.property.dictionary.options}
                            rows={{ value: "id", label: "valueString" }}
                            style={{ paddingRight: 0 }}
                            value={formik.values.properties[prop.property.id]}
                            tags={true}
                          />
                        </Col>
                      );

                    case 6:
                      return (
                        <Col md={6} key={index}>
                          <Row>
                            {prop.property?.children.map((child, subIndex) => {
                              return (
                                <Col md={6} key={subIndex}>
                                  <label>{child.nameString}</label>
                                  <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                    <span className="input-group-btn input-group-prepend">
                                      <button
                                        className="btn btn-primary bootstrap-touchspin-down"
                                        type="button"
                                        onClick={() => {
                                          const _properties = {
                                            ...formik.values.properties,
                                          };
                                          if (
                                            Number(_properties[child.id]) > 0
                                          ) {
                                            _properties[child.id] =
                                              Number(_properties[child.id]) - 1;
                                          }
                                          formik.setFieldValue(
                                            "properties",
                                            _properties,
                                          );
                                        }}
                                      >
                                        -
                                      </button>
                                    </span>
                                    <input
                                      data-toggle="touchspin"
                                      type="text"
                                      value={formik.values.properties[child.id]}
                                      className="form-control"
                                    />
                                    <span className="input-group-btn input-group-append">
                                      <button
                                        className="btn btn-primary bootstrap-touchspin-up"
                                        type="button"
                                        onClick={() => {
                                          const _properties = {
                                            ...formik.values.properties,
                                          };
                                          if (
                                            !_properties[child.id] ||
                                            _properties[child.id] === ""
                                          ) {
                                            _properties[child.id] = 1;
                                          } else {
                                            _properties[child.id] =
                                              Number(_properties[child.id]) + 1;
                                          }
                                          formik.setFieldValue(
                                            "properties",
                                            _properties,
                                          );
                                        }}
                                      >
                                        +
                                      </button>
                                    </span>
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </Col>
                      );
                  }
                })}
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
