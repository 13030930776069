import { gql } from "@apollo/client";

export const GET_PLACES = gql`
  query {
    places {
      id
      name {
        lang
        value
      }
      area {
        name {
          lang
          value
        }
      }
    }
  }
`;

export const GET_PLACES_FILTER = gql`
  query ($area_id: Int, $lang: String!) {
    places(area_id: $area_id) {
      id
      nameString(lang: $lang)
    }
  }
`;

export const GET_PLACE = gql`
  query ($id: Int!, $lang: String) {
    places(id: $id) {
      id
      name {
        lang
        value
      }
      alternative_name {
        lang
        value
      }
      area {
        id
        city {
          id
          country {
            id
          }
        }
      }
      lat
      lng
    }
    countries {
      id
      nameString(lang: $lang)
    }
  }
`;
