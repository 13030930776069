import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Modal } from "react-bootstrap";

export const Selected = ({
  name,
  label,
  value,
  onChange,
  error = false,
  style = {},
  rows = { value: "id", label: "name" },
  modalForm,
  touched,
  staticOptions = [],
  onRefresh,
  isClearable = true,
  tags = false,
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [showAdd, toggleAdd] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    if (value && options.length > 0) {
      if (!tags) {
        const dVal = options.find((option) => {
          console.log("🚀", option.value, value);
          return (
            option.value === value ||
            Number(option.value) === Number(value) ||
            String(option.value) === String(value)
          );
        });
        console.log("🚀dVal", dVal);
        setDefaultValue(dVal);
      } else {
        const dVal = options.filter((option) => {
          return (
            value.includes(Number(option.value)) ||
            value.includes(option.value) ||
            value.includes(String(option.value))
          );
        });
        setDefaultValue(dVal);
      }
    } else {
      setDefaultValue(null);
    }
  }, [value, options]);

  useEffect(() => {
    if (staticOptions) {
      const arr = [];
      staticOptions.map((item) => {
        arr.push({
          value: item[rows.value],
          label: item[rows.label],
        });
      });
      setOptions(arr);
    }
  }, [staticOptions]);

  return (
    <div
      className={
        error && touched
          ? "form-floating mb-3 form-by-select is-invalid"
          : "form-floating mb-3 form-by-select"
      }
      style={{ ...style, paddingRight: !modalForm ? 0 : 28 }}
    >
      <Select
        isMulti={tags}
        isClearable={isClearable}
        className="react-select react-select-container mt-1"
        classNamePrefix="react-select"
        options={options}
        placeholder={t("Выберите")}
        value={defaultValue}
        onChange={(e) => onChange(tags ? e : e?.value)}
      ></Select>
      <label htmlFor={name}>{t(label)}</label>
      {modalForm && (
        <div className={"select-control"}>
          <div onClick={() => toggleAdd(true)}>
            <i className={"fe-plus"} />
          </div>
          <div onClick={() => onRefresh()}>
            <i className={"fe-refresh-cw"} />
          </div>
        </div>
      )}

      {modalForm && (
        <Modal show={showAdd} size={"lg"}>
          <Modal.Header closeButton onClick={() => toggleAdd(false)}>
            <h4 className="modal-title">{t("Добавить")}</h4>
          </Modal.Header>
          <Modal.Body>{modalForm}</Modal.Body>
        </Modal>
      )}
    </div>
  );
};
