import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu } from "./Sidebar/Menu";
import { useQuery } from "@apollo/client";
import { GET_BADGE } from "../graphql/Queries/BadgeQuery";
import { useDispatch, useSelector } from "react-redux";
import { addComplaint, setBadge } from "../redux/slices/badgeSlice";

export const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const complaint = useSelector((state) => state.badge.complaint);
  const estates = useSelector((state) => state.badge.estates);
  const tickets = useSelector((state) => state.badge.tickets);
  const agent_request = useSelector((state) => state.badge.agent_request);

  const { data } = useQuery(GET_BADGE, {
    pollInterval: 600000, // 10 хвилин в мілісекундах
  });

  useEffect(() => {
    if (data) {
      const badge = JSON.parse(data.badge);
      dispatch(setBadge(badge));
    }
  }, [data]);

  const menu = [
    {
      icon: "ri-ruler-2-line",
      name: t("Конструктор"),
      slug: "constructor",
      children: [
        {
          name: t("Справочники"),
          slug: "dictionaries",
        },
        {
          name: t("Свойства"),
          slug: "properties",
        },
        {
          name: t("Шаги/блоки"),
          slug: "steps",
        },
        {
          name: t("Группы свойств"),
          slug: "property-groups",
        },
      ],
    },
    {
      icon: "ri-map-pin-line",
      name: t("Гео данные"),
      slug: "geo",
      children: [
        {
          name: t("Страны"),
          slug: "countries",
        },
        {
          name: t("Города"),
          slug: "cities",
        },
        {
          name: t("Районы"),
          slug: "areas",
        },
        {
          name: t("Поселки"),
          slug: "places",
        },
        {
          name: t("Метро"),
          slug: "metro",
        },
        {
          name: t("Ориентиры"),
          slug: "points",
        },
      ],
    },
    {
      icon: "ri-home-4-line",
      name: t("Недвижимость"),
      slug: "real-estate",
      badge: estates,
      children: [
        {
          name: t("Типы объектов"),
          slug: "type-objects",
        },
        {
          name: t("Объекты"),
          slug: "estates",
          badge: estates,
        },
        {
          name: t("Жилые зоны"),
          slug: "complexes",
        },
        {
          name: t("Причины отклонения"),
          slug: "estate-reject-reasons",
        },
      ],
    },
    {
      icon: "ri-function-line",
      name: t("Меню"),
      slug: "menu",
      children: [
        {
          name: t("Страницы"),
          slug: "pages",
        },
        {
          name: t("Быстрые ссылки"),
          slug: "fast-links",
        },
        {
          name: t("Меню в футере"),
          slug: "footer-menu",
        },
      ],
    },
    {
      icon: "ri-tools-fill",
      name: t("Настройки"),
      slug: "#",
      children: [
        {
          name: t("Главная страница"),
          slug: "home-setting",
        },
        {
          name: t("Основные"),
          slug: "settings",
        },
      ],
    },
    {
      icon: "ri-translate",
      name: t("Переводы"),
      slug: "lang",
      children: false,
    },
    {
      icon: "ri-coupon-3-line",
      name: t("Промокоды"),
      slug: "promo-codes",
      children: false,
    },
    {
      icon: "ri-newspaper-line",
      name: t("Новости"),
      slug: "#",
      children: [
        {
          name: t("Управление новостями"),
          slug: "news",
        },
        {
          name: t("Категории"),
          slug: "category-news",
        },
        {
          name: t("Теги"),
          slug: "tag-news",
        },
      ],
    },
    {
      icon: "ri-question-answer-line",
      name: t("FAQ"),
      slug: "#",
      children: [
        {
          name: t("Группы"),
          slug: "faq-tabs",
        },
        {
          name: t("FAQ"),
          slug: "faq",
        },
      ],
    },
    {
      icon: "ri-git-merge-line",
      name: t("Парсеры"),
      slug: "parsers",
      children: false,
    },
    {
      icon: "ri-mail-line",
      name: t("Шаблоны уведомлений"),
      slug: "template-notifications",
      children: false,
    },
    {
      icon: "ri-alarm-warning-line",
      name: t("Жалобы"),
      slug: "complaints",
      children: false,
      badge: complaint,
    },
    {
      icon: "ri-briefcase-2-line",
      name: t("Услуги"),
      slug: "#",
      children: [
        {
          name: t("Список услуг"),
          slug: "services",
        },
      ],
    },
    {
      icon: "ri-message-2-line",
      name: t("Обращения"),
      slug: "tickets",
      children: false,
      badge: tickets,
    },
    {
      icon: "ri-folder-user-fill",
      name: t("Запросы на агента"),
      slug: "agent-request",
      children: false,
      badge: agent_request,
    },
  ];
  return (
    <div id="sidebar-menu">
      <ul id="side-menu">
        {menu.map((item, index) => {
          return <Menu key={index} item={item} />;
        })}
      </ul>
    </div>
  );
};
