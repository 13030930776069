import { gql } from "@apollo/client";

export const GET_PARSERS = gql`
  query {
    parser {
      id
      name
      link
      enable
      queue
    }
    estateError
  }
`;

export const GET_PARSER = gql`
  query ($id: Int!, $lang: String!) {
    parser(id: $id) {
      id
      name
      link
      object_type_id
      container
      item
      item_link
      prefix_link
      target
      address
      price
      complex
      title
      text
      city
      properties
      estate_type
      enable
      ad_send
    }
    objectType {
      id
      nameString(lang: $lang)
    }
  }
`;
