import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { GET_PARSER } from "../../graphql/Queries/ParserQuery";
import { TextInput } from "../../components/Form/TextInput";
import { GET_LIST_PROPERTIES } from "../../graphql/Queries/PropertyQuery";
import { MODIFY_PARSER } from "../../graphql/Mutations/ParserMutation";
import { ESTATE_TYPE, TARGET } from "../../utils/Dictionaries";

export const FormParser = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_PARSER);
  const { data } = useQuery(GET_PARSER, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: "",
      link: "",
      object_type_id: null,
      target: null,
      estate_type: null,
      address: "",
      price: "",
      complex: "",
      title: "",
      text: "",
      city: "",
      container: "",
      item: "",
      prefix_link: "",
      item_link: "",
      properties: [],
      enable: 1,
      ad_send: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Объязательное поле")),
      link: Yup.string().required(t("Объязательное поле")),
      object_type_id: Yup.number().required(t("Объязательное поле")),
      container: Yup.string().required(t("Объязательное поле")),
      item: Yup.string().required(t("Объязательное поле")),
      item_link: Yup.string().required(t("Объязательное поле")),
    }),
    onSubmit: (values) => {
      console.log("[Log]", values);
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          name: values.name,
          link: values.link,
          container: values.container,
          item: values.item,
          item_link: values.item_link,
          prefix_link: values.prefix_link,
          address: values.address,
          price: values.price,
          complex: values.complex,
          title: values.title,
          text: values.text,
          city: values.city,
          object_type_id: Number(values.object_type_id),
          target: Number(values.target),
          properties: JSON.stringify(properties),
          estate_type: Number(values.estate_type),
          enable: Number(values.enable),
          ad_send: Number(values.ad_send),
        },
      })
        .then((response) => {
          navigate(url);
          Notification(t("Все хорошо!"), "success");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const [objectTypes, setObjectTypes] = useState([]);
  const [queryProperty] = useLazyQuery(GET_LIST_PROPERTIES);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    if (data && data.parser[0]) {
      formik.setFieldValue("id", data.parser[0].id);
      formik.setFieldValue("name", data.parser[0].name);
      formik.setFieldValue("link", data.parser[0].link);
      formik.setFieldValue("container", data.parser[0].container);
      formik.setFieldValue("item", data.parser[0].item);
      formik.setFieldValue("item_link", data.parser[0].item_link);
      formik.setFieldValue("prefix_link", data.parser[0].prefix_link);
      formik.setFieldValue("object_type_id", data.parser[0].object_type_id);
      formik.setFieldValue("target", data.parser[0].target);
      formik.setFieldValue("price", data.parser[0].price);
      formik.setFieldValue("address", data.parser[0].address);
      formik.setFieldValue("city", data.parser[0].city);
      formik.setFieldValue("title", data.parser[0].title);
      formik.setFieldValue("text", data.parser[0].text);
      formik.setFieldValue("enable", Number(data.parser[0].enable));
      formik.setFieldValue("estate_type", data.parser[0].estate_type);
      formik.setFieldValue("ad_send", Number(data.parser[0].ad_send));
      queryProperty({
        variables: {
          lang: i18next.language,
          id: Number(data.parser[0].object_type_id),
        },
      }).then((response) => {
        const props = JSON.parse(data.parser[0].properties);
        const _arr = [...response.data.property];
        const newArr = _arr.map((item) => {
          if (props[item.id] !== undefined) {
            return { ...item, element: props[item.id] };
          }
          return item;
        });
        setProperties(newArr);
      });
    }
    if (data && data.objectType) {
      setObjectTypes(data.objectType);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Добавить парсер" : "Редактировать парсер"}
        breadcrumbs={[
          {
            slug: url,
            name: "Парсеры",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-3">
                  <TextInput
                    label={"Название"}
                    value={formik.values.name}
                    onChange={(event) => {
                      formik.setFieldValue(`name`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.name}
                    touched={formik.touched.name}
                    name={"name"}
                  />
                </div>
                <div className="col-md-3">
                  <TextInput
                    label={"Ссылка"}
                    value={formik.values.link}
                    onChange={(event) => {
                      formik.setFieldValue(`link`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.link}
                    touched={formik.touched.link}
                    name={"link"}
                  />
                </div>
                <div className="col-md-3">
                  <Selected
                    label={"Состояние"}
                    onChange={(value) => {
                      formik.setFieldValue(`enable`, value);
                    }}
                    error={formik.errors.enable}
                    touched={formik.touched.enable}
                    name={"enable"}
                    staticOptions={[
                      { id: 1, name: "Включен" },
                      { id: 2, name: "Отключен" },
                    ]}
                    rows={{ value: "id", label: "name" }}
                    value={formik.values.enable}
                    style={{ paddingRight: 0 }}
                  />
                </div>
                <div className="col-md-3">
                  <Selected
                    label={"Рассылка рекламы"}
                    onChange={(value) => {
                      formik.setFieldValue(`ad_send`, value);
                    }}
                    error={formik.errors.ad_send}
                    touched={formik.touched.ad_send}
                    name={"ad_send"}
                    staticOptions={[
                      { id: 2, name: "Нет" },
                      { id: 1, name: "Да" },
                    ]}
                    rows={{ value: "id", label: "name" }}
                    value={formik.values.ad_send}
                    style={{ paddingRight: 0 }}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Контейнер"}
                    value={formik.values.container}
                    onChange={(event) => {
                      formik.setFieldValue(`container`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.container}
                    touched={formik.touched.container}
                    name={"container"}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Блок объявления"}
                    value={formik.values.item}
                    onChange={(event) => {
                      formik.setFieldValue(`item`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.item}
                    touched={formik.touched.item}
                    name={"item"}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Приставка к ссылке"}
                    value={formik.values.prefix_link}
                    onChange={(event) => {
                      formik.setFieldValue(`prefix_link`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.prefix_link}
                    touched={formik.touched.prefix_link}
                    name={"prefix_link"}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Блок с ссылкой"}
                    value={formik.values.item_link}
                    onChange={(event) => {
                      formik.setFieldValue(`item_link`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.item_link}
                    touched={formik.touched.item_link}
                    name={"item_link"}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Тип объекта"}
                    onChange={(value) => {
                      formik.setFieldValue(`object_type_id`, value);
                      queryProperty({
                        variables: {
                          lang: i18next.language,
                          id: Number(value),
                        },
                      }).then((response) => {
                        setProperties(response.data.property);
                      });
                    }}
                    error={formik.errors.object_type_id}
                    touched={formik.touched.object_type_id}
                    name={"object_type_id"}
                    staticOptions={objectTypes}
                    rows={{ value: "id", label: "nameString" }}
                    value={formik.values.object_type_id}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Тип сделки"}
                    onChange={(value) => {
                      formik.setFieldValue(`target`, value);
                    }}
                    error={formik.errors.target}
                    touched={formik.touched.target}
                    name={"target"}
                    staticOptions={TARGET}
                    rows={{ value: "id", label: "name" }}
                    value={formik.values.target}
                  />
                </div>
                <div className="col-md-4">
                  <Selected
                    label={"Тип нежвижимости"}
                    onChange={(value) => {
                      formik.setFieldValue(`estate_type`, value);
                    }}
                    error={formik.errors.estate_type}
                    touched={formik.touched.estate_type}
                    name={"estate_type"}
                    staticOptions={ESTATE_TYPE}
                    rows={{ value: "id", label: "name" }}
                    value={formik.values.estate_type}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    label={"Адрес"}
                    value={formik.values.address}
                    onChange={(event) => {
                      formik.setFieldValue(`address`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.address}
                    touched={formik.touched.address}
                    name={"address"}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    label={"Цена"}
                    value={formik.values.price}
                    onChange={(event) => {
                      formik.setFieldValue(`price`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.price}
                    touched={formik.touched.price}
                    name={"price"}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    label={"Комплекс"}
                    value={formik.values.complex}
                    onChange={(event) => {
                      formik.setFieldValue(`complex`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.complex}
                    touched={formik.touched.complex}
                    name={"complex"}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    label={"Заголовок"}
                    value={formik.values.title}
                    onChange={(event) => {
                      formik.setFieldValue(`title`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.title}
                    touched={formik.touched.title}
                    name={"title"}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    label={"Текст"}
                    value={formik.values.text}
                    onChange={(event) => {
                      formik.setFieldValue(`text`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.text}
                    touched={formik.touched.text}
                    name={"text"}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    label={"Город"}
                    value={formik.values.city}
                    onChange={(event) => {
                      formik.setFieldValue(`city`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.city}
                    touched={formik.touched.city}
                    name={"city"}
                  />
                </div>
                {properties.map((prop) => {
                  return (
                    <div className="col-md-6">
                      <TextInput
                        label={prop.nameString}
                        value={prop.element}
                        onChange={(event) => {
                          const _props = properties.map((_prop) => {
                            if (Number(_prop.id) === Number(prop.id)) {
                              return { ..._prop, element: event.target.value };
                            }
                            return _prop;
                          });
                          setProperties(_props);
                        }}
                        name={"link"}
                      />
                    </div>
                  );
                })}
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
