import LogoDark from "../assets/images/city.az.svg";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Notification } from "../utils/Notification";
import { setAuth, setUser } from "../redux/slices/authSlice";
import { Button } from "../components/Button";

export const Login = () => {
  const { t } = useTranslation();
  const [showPassword, toggleShowPassword] = useState(false);
  const auth = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t("Укажите")),
      email: Yup.string().email(t("Не верный email")).required(t("Укажите")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      axios
        .post("/login", {
          email: values.email,
          password: values.password,
        })
        .then((response) => {
          dispatch(setAuth(response.data.authorization));
          dispatch(setUser(response.data.user));
          navigate("/home");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="account-pages mt-5 mb-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-4">
            <div className="card">
              <div className="card-body p-4">
                <div className="text-center w-75 m-auto">
                  <div className="auth-logo">
                    <div className="logo logo-dark text-center">
                      <span className="logo-lg">
                        <img src={LogoDark} alt="" height="22" />
                      </span>
                    </div>

                    <div className="logo logo-light text-center">
                      <span className="logo-lg">
                        <img src={LogoDark} alt="" height="22" />
                      </span>
                    </div>
                  </div>
                  <p className="text-muted mb-4 mt-3">
                    {t("Ввведите email и пароль для входа в систему")}
                  </p>
                </div>

                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-2">
                    <label htmlFor="email" className="form-label">
                      {t("Email")}
                    </label>
                    <input
                      className={
                        formik.touched.email && formik.errors.email
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="email"
                      id={"email"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      placeholder={t("Введите свой email")}
                    />
                  </div>

                  <div className="mb-2">
                    <label htmlFor="password" className="form-label">
                      {t("Пароль")}
                    </label>
                    <div className="input-group input-group-merge">
                      <input
                        type={showPassword ? "text" : "password"}
                        className={
                          formik.touched.password && formik.errors.password
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder={t("Введите свой пароль")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        id={"password"}
                      />
                      <div
                        className={
                          showPassword
                            ? "input-group-text show-password"
                            : "input-group-text"
                        }
                        data-password="false"
                        onClick={() => toggleShowPassword(!showPassword)}
                      >
                        <span className="password-eye"></span>
                      </div>
                    </div>
                  </div>

                  <div className="d-grid mb-0 text-center">
                    <Button
                      label={"Войти"}
                      style={"btn-primary"}
                      loading={loading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
