import { gql } from "@apollo/client";

export const GET_STEPS = gql`
  query {
    steps {
      id
      name {
        lang
        value
      }
      name_system {
        lang
        value
      }
      help_title {
        lang
        value
      }
      help_text {
        lang
        value
      }
      is_system
      key
    }
  }
`;

export const GET_STEP = gql`
  query ($id: Int!) {
    steps(id: $id) {
      id
      name {
        lang
        value
      }
      name_system {
        lang
        value
      }
      help_title {
        lang
        value
      }
      help_text {
        lang
        value
      }
      is_system
      key
    }
  }
`;

export const GET_STEPS_WITH_PROP_GROUP = gql`
  query ($lang: String!) {
    steps {
      id
      name {
        lang
        value
      }
      name_system {
        lang
        value
      }
      help_title {
        lang
        value
      }
      help_text {
        lang
        value
      }
      is_system
      key
    }
    listType: list(method: "PropertyGroup::listType", lang: $lang) {
      id
      name
    }
  }
`;
